<template>
  <el-dialog class="video-view-dialog" title="视频详情" :visible.sync="visible" :videoViewDialogVisible="videoViewDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
    <el-row>
      <el-col :span="24">
        <video class="video-view video" v-if="type == 'video'" :src="videoUrl" controls></video>
        <audio class="video-view" v-if="type == 'audio'" :src="videoUrl" controls></audio>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "videoViewDialog",
  props: {
    videoViewDialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    videoUrl: {
      type: String,
      default: "",
    }
  },
  watch: {
    videoViewDialogVisible() {
      this.visible = this.videoViewDialogVisible;
    },
  },
  data(){
    return{
      visible: false,
    }
  },
  methods: {
    //视频预览弹窗关闭
    handleClose() {
      this.$emit("closeDialog", false);
    },
  }
}
</script>
<style lang="scss" scoped>
.video-view{
  width: 100%;
  &.video{
    height: 60vh;
  }
}
</style>